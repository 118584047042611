import '@signal24/vue-foundation/dist/vue-foundation.css';
import './otel';

import Tracker from '@openreplay/tracker';
import { InputMode } from '@openreplay/tracker/lib/modules/input';
import TrackerAssist from '@openreplay/tracker-assist';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './app.vue';
import { OpenReplay } from './shared/services/openreplay';
import { setupVf } from './vf.setup';

// lazy
import('@fortawesome/fontawesome-pro/css/all.css');

const app = createApp(App);

if (import.meta.env.VITE_APP_SENTRY_DSN) {
    const matches = import.meta.env.VITE_APP_SENTRY_DSN.match(/\/\/(.+)@.+\/(\d+)$/);
    if (matches) {
        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            environment: import.meta.env.MODE,
            release: `c2c-agent-spa@${import.meta.env.VITE_APP_VERSION}`,
            logErrors: true,
            tunnel: `https://sentry.s24.dev/proxy/${matches[2]}/${matches[1]}`,
            beforeBreadcrumb(breadcrumb) {
                const url = breadcrumb.category === 'fetch' ? breadcrumb.data?.url : null;
                return url?.includes('openreplay.s24.io') || url?.includes('s24.dev') ? null : breadcrumb;
            }
        });
    }
}

if (import.meta.env.VITE_APP_OPENREPLAY_URL && import.meta.env.VITE_APP_OPENREPLAY_KEY) {
    OpenReplay.tracker = new Tracker({
        __DISABLE_SECURE_MODE: import.meta.env.MODE === 'development',
        ingestPoint: import.meta.env.VITE_APP_OPENREPLAY_URL,
        projectKey: import.meta.env.VITE_APP_OPENREPLAY_KEY,
        network: {
            ignoreHeaders: ['authorization'],
            capturePayload: true,
            sessionTokenHeader: false,
            captureInIframes: true,
            failuresOnly: false,
            sanitizer(data) {
                // we don't need payloads for non-error responses
                if (data.status < 400) {
                    data.request.body = null;
                    data.response.body = null;
                }
                return data;
            }
        },
        obscureTextEmails: false,
        obscureInputEmails: false,
        defaultInputMode: InputMode.Plain
    });
    OpenReplay.tracker.use(TrackerAssist({}));
}

setupVf(app);

const pinia = createPinia();
app.use(pinia);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: () => import('./components/agent.vue') },
        {
            path: '/manager',
            component: () => import('./components/manager.vue'),
            children: [
                { path: '', redirect: '/manager/agents' },
                { path: 'agents', component: () => import('./components/manager/agents.vue') },
                { path: 'calls', component: () => import('./components/manager/calls.vue') }
            ]
        }
    ]
});
app.use(router);

app.mount(document.body);
